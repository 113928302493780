<template>
  <section>
    <FormulateForm
      ref="productKitForm"
      name="productKitForm"
    >
      <b-card-actions
        action-collapse
        :title="`${$t('Kit de Produtos')}${kit.id ? ` #${kit.id}` : ''}`"
        :show-loading="loading"
      >
        <b-row>
          <b-col md="3">
            <b-row>
              <b-col md="12">
                <b-tabs fill>
                  <b-tab
                    active
                    :title="$t('Quadrada')"
                  >
                    <FormulateInput
                      id="image-square-local"
                      v-model="imageSquareLocal"
                      type="uploader"
                      deletable="true"
                      max-files="1"
                      :meta="false"
                      :disabled="loading"
                    />
                  </b-tab>
                  <b-tab :title="$t('Wide')">
                    <FormulateInput
                      id="image-wide-local"
                      v-model="imageWideLocal"
                      type="uploader"
                      deletable="true"
                      max-files="1"
                      :meta="false"
                      :disabled="loading"
                    />
                  </b-tab>
                </b-tabs>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <div style="overflow-x: auto">
                  <barcode
                    class="text-center"
                    :value="kit.ean || 1234567890"
                  >
                    EAN
                  </barcode>
                </div>
                <FormulateInput
                  id="ean"
                  v-model.trim="kit.ean"
                  :label="$t('Código de Barras (EAN/GTIN)')"
                  type="text"
                  inputmode="numeric"
                  :pattern="/[0-9]*/"
                  maxlength="15"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col md="9">
            <b-row>
              <b-col md="12">
                <b-row>
                  <b-col md="6">
                    <FormulateInput
                      id="name"
                      v-model="kit.name"
                      :is-edit="false"
                      type="text"
                      :label="$t('Nome')"
                      validation="required"
                      class="required input-uppercase"
                    />
                  </b-col>
                  <b-col md="2">
                    <FormulateInput
                      id="active_online"
                      v-model="kit.activeOnline"
                      type="switch"
                      :label="$t('Vender no site')"
                    />
                  </b-col>
                  <b-col md="4">
                    <FormulateInput
                      id="is_cooler_beer"
                      v-model="kit.isBeerCoolerKit"
                      type="switch"
                      :label="$t('Chopeira?')"
                    />
                  </b-col>
                  <b-col md="6">
                    <FormulateInput
                      id="description"
                      v-model="kit.description"
                      type="textarea"
                      :label="$t('Descrição')"
                      rows="5"
                    />
                  </b-col>
                  <b-col md="4">
                    <FormulateInput
                      id="kit-period"
                      ref="inputPeriod"
                      v-model="kit.period"
                      type="date-range-picker"
                      :label="$t('Período')"
                      :instruction="$t('Período que o kit estará disponivel para venda.')"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="3">
                    <FormulateInput
                      id="kit-allow_all_stores"
                      v-model="kit.allowAllStores"
                      type="switch"
                      :instruction="
                        $t('Quando selecionado, o Kit estará disponível para todas as lojas.')
                      "
                      :label="$t('Todas as lojas?')"
                    />
                  </b-col>
                  <b-col
                    v-if="!kit.allowAllStores"
                    md="6"
                  >
                    <e-store-combo
                      id="kit-stores_selected"
                      v-model="kit.storesSelected"
                      type="vue-select"
                      class="required"
                      :multiple="true"
                      :label="$t('Lojas participantes')"
                      validation="required"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="3">
                    <FormulateInput
                      id="kit-is_credit"
                      v-model="kit.isCredit"
                      type="switch"
                      :instruction="
                        $t('Quando selecionado, o Kit estará disponível para venda como crédito')
                      "
                      :label="$t('Produto Crédito?')"
                    />
                  </b-col>
                  <b-col
                    v-if="kit.isCredit"
                    md="4"
                  >
                    <FormulateInput
                      id="kit-credit_type"
                      v-model="kit.creditType"
                      type="vue-select"
                      class="required"
                      validation="required"
                      :label="$t('Tipo de Crédito')"
                      :placeholder="$t('Selecione')"
                      :options="productCreditTypes()"
                    />
                  </b-col>
                  <b-col
                    v-if="kit.isCredit"
                    md="3"
                  >
                    <FormulateInput
                      id="kit-is_available_for_gift_card"
                      v-model="kit.isAvailableForChoppGiftCard"
                      type="switch"
                      :instruction="
                        $t(
                          'Quando selecionado, o Kit estará disponível para venda utilizando um cartão de presente de chope'
                        )
                      "
                      :label="$t('Disp. p/ Gift Card?')"
                    />
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-actions>

      <b-card-actions
        action-collapse
        :title="$t('Produtos')"
        :show-loading="loading"
      >
        <b-row>
          <b-col class="d-flex justify-content-end">
            <e-button
              variant="primary"
              :text="$t('Adicionar Produto')"
              @click="onShowSidebar()"
            />
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col>
            <b-table
              ref="productTable"
              show-empty
              responsive
              class="bordered"
              :fields="fields"
              :items="getKitItems"
              :empty-text="$t('Adicione produtos ao seu Kit')"
            >
              <template #cell(unitDiscount)="row">
                {{ formatDiscount(row.item.unitDiscount, row.item.discountType) }}
              </template>

              <template #cell(skuProduct)="row">
                <e-product-router-link
                  :sku-id="row.item.skuProduct.id"
                  :product-id="row.item.skuProduct.productId"
                  :product-name="row.item.skuProduct.name"
                  :ean="row.item.skuProduct.ean"
                />
              </template>

              <template #cell(action)="row">
                <e-grid-actions
                  :show-update="true"
                  :show-delete="true"
                  @update="onShowSidebar(row)"
                  @delete="onDeleteKitItem(row)"
                />
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-card-actions>
    </FormulateForm>

    <e-crud-actions
      :busy="loading"
      @save="onSaveKit"
      @cancel="onCancel"
    />

    <product-kit-item-sidebar
      ref="productKitItemSidebar"
      :kit-items="getKitItems"
    />
  </section>
</template>

<script>
import { BRow, BCol, BTable, BTab, BTabs } from 'bootstrap-vue'
import VueBarcode from 'vue-barcode'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import EButton from '@/views/components/EButton.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { formulateHelper, discountTypes, uploader, productDomains } from '@/mixins'
import { ECrudActions, EGridActions, EStoreCombo, EProductRouterLink } from '@/views/components'
import ProductKitItemSidebar from './ProductKitItemSidebar.vue'

export default {
  name: 'ProductKitMaintain',
  components: {
    BCardActions,
    BRow,
    BCol,
    BTab,
    BTabs,
    EButton,
    BTable,
    ECrudActions,
    barcode: VueBarcode,
    EGridActions,
    ProductKitItemSidebar,
    EStoreCombo,
    EProductRouterLink,
  },

  mixins: [formulateHelper, discountTypes, uploader, productDomains],

  data() {
    return {
      loading: false,
      imageSquareLocal: [],
      imageWideLocal: [],
    }
  },

  computed: {
    ...mapState('pages/catalog/productKits/productKitMaintain', ['kit']),
    ...mapGetters('pages/catalog/productKits/productKitMaintain', ['getKitItems']),

    isEdit() {
      return !!this.$route.params.id
    },

    fields() {
      return [
        {
          label: this.$t('Ações'),
          key: 'action',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Produto'),
          thClass: 'text-center',
          tdClass: 'text-left',
          key: 'skuProduct',
          formatter: val => val.name,
        },
        {
          label: this.$t('Quantidade'),
          key: 'quantity',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Desconto unit.'),
          key: 'unitDiscount',
          thClass: 'text-center',
          tdClass: 'text-right',
          thStyle: { width: '150px' },
        },
      ]
    },
  },

  async mounted() {
    if (this.isEdit) {
      try {
        this.loading = true
        await this.stLoadKitById(this.$route.params.id)

        this.imageSquareLocal = this.kit.imageSquare
          ? [this.getFileInstance(this.kit.imageSquare)]
          : []
        this.imageWideLocal = this.kit.imageWide ? [this.getFileInstance(this.kit.imageWide)] : []
      } catch (error) {
        this.showError({ error })
      } finally {
        this.loading = false
      }
    }
  },

  methods: {
    ...mapActions('pages/catalog/productKits/productKitMaintain', {
      stRemoveKitItem: 'removeKitItem',
      stCleanState: 'cleanState',
      stLoadKitById: 'loadKitById',
    }),

    onShowSidebar(row) {
      this.$refs.productKitItemSidebar.show(row)
    },

    async onSaveKit() {
      this.$refs.productKitForm.showErrors()
      if (!this.validate()) {
        this.showInvalidDataMessage({})
        return
      }

      try {
        this.loading = true
        const images = await this.uploadImages()
        const [imageWide, imageSquare] = images

        const { kitItems, productKitStores, storesSelected, period } = this.kit

        const selectedStores = [...storesSelected]
        const kitStoresUpdated = [...productKitStores].map(kitStore => {
          const result = { ...kitStore, storeId: kitStore.id }
          if (!selectedStores.some(storeId => storeId === kitStore.id.toString())) {
            result.isDeleted = true
          }
          return result
        })
        const newStores = selectedStores
          .filter(storeId => !kitStoresUpdated.some(store => store.id.toString() === storeId))
          .map(storeId => ({ storeId }))

        const products = kitItems.map(kItem => ({
          id: kItem.id,
          productId: kItem.skuProduct.productId,
          skuId: kItem.skuProduct.id,
          quantity: kItem.quantity,
          quantityIsEditable: kItem.quantityIsEditable,
          discountType: kItem.discountType,
          unitDiscount: kItem.unitDiscount,
          isDeleted: kItem.isDeleted,
        }))

        const body = {
          ...this.kit,
          products,
          imageWide: imageWide?.url,
          imageSquare: imageSquare?.url,
          startDate: period.startDate,
          endDate: period.endDate,
          stores: [...newStores, ...kitStoresUpdated],
        }
        await this.$http({
          url: '/api/product-kits',
          method: this.isEdit ? 'PUT' : 'POST',
          data: body,
        })

        this.showSuccess({
          message: this.$t(`${this.isEdit ? 'Atualização' : 'Inclusão'} realizada com sucesso`),
        })
        this.$router.push({ name: 'product-kit-list' })
      } catch (error) {
        this.showError({ error })
      } finally {
        this.loading = false
      }
    },

    async uploadImages() {
      let resultWide = null
      let resultSquare = null
      const result = await this.upload({
        files: [...this.imageWideLocal, ...this.imageSquareLocal],
        destinationPath: 'images/products',
      })
      if (this.imageWideLocal.length) {
        ;[resultWide] = result
      }
      if (this.imageSquareLocal.length) {
        ;[resultSquare] = result.slice(result.length > 1 ? 1 : 0)
      }
      const nullResult = { url: null }
      return [resultWide || nullResult, resultSquare || nullResult]
    },

    async onDeleteKitItem(row) {
      const confirm = await this.confirm()
      if (confirm) {
        const { localId, id } = row.item
        this.stRemoveKitItem(localId || id)
      }
    },

    onCancel() {
      this.stCleanState()
      this.$router.push({ name: 'product-kit-list' })
    },

    formatDiscount(value, type) {
      return type === this.discountTypesEnum.PERCENTAGE
        ? this.$options.filters.percentage(value)
        : this.$options.filters.currency(value)
    },

    validate() {
      if (this.$refs.productKitForm.hasErrors) return false

      return true
    },
  },
}
</script>
