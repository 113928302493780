var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "FormulateForm",
        { ref: "productKitForm", attrs: { name: "productKitForm" } },
        [
          _c(
            "b-card-actions",
            {
              attrs: {
                "action-collapse": "",
                title:
                  "" +
                  _vm.$t("Kit de Produtos") +
                  (_vm.kit.id ? " #" + _vm.kit.id : ""),
                "show-loading": _vm.loading,
              },
            },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { md: "12" } },
                            [
                              _c(
                                "b-tabs",
                                { attrs: { fill: "" } },
                                [
                                  _c(
                                    "b-tab",
                                    {
                                      attrs: {
                                        active: "",
                                        title: _vm.$t("Quadrada"),
                                      },
                                    },
                                    [
                                      _c("FormulateInput", {
                                        attrs: {
                                          id: "image-square-local",
                                          type: "uploader",
                                          deletable: "true",
                                          "max-files": "1",
                                          meta: false,
                                          disabled: _vm.loading,
                                        },
                                        model: {
                                          value: _vm.imageSquareLocal,
                                          callback: function ($$v) {
                                            _vm.imageSquareLocal = $$v
                                          },
                                          expression: "imageSquareLocal",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-tab",
                                    { attrs: { title: _vm.$t("Wide") } },
                                    [
                                      _c("FormulateInput", {
                                        attrs: {
                                          id: "image-wide-local",
                                          type: "uploader",
                                          deletable: "true",
                                          "max-files": "1",
                                          meta: false,
                                          disabled: _vm.loading,
                                        },
                                        model: {
                                          value: _vm.imageWideLocal,
                                          callback: function ($$v) {
                                            _vm.imageWideLocal = $$v
                                          },
                                          expression: "imageWideLocal",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { md: "12" } },
                            [
                              _c(
                                "div",
                                { staticStyle: { "overflow-x": "auto" } },
                                [
                                  _c(
                                    "barcode",
                                    {
                                      staticClass: "text-center",
                                      attrs: {
                                        value: _vm.kit.ean || 1234567890,
                                      },
                                    },
                                    [_vm._v(" EAN ")]
                                  ),
                                ],
                                1
                              ),
                              _c("FormulateInput", {
                                attrs: {
                                  id: "ean",
                                  label: _vm.$t("Código de Barras (EAN/GTIN)"),
                                  type: "text",
                                  inputmode: "numeric",
                                  pattern: /[0-9]*/,
                                  maxlength: "15",
                                },
                                model: {
                                  value: _vm.kit.ean,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.kit,
                                      "ean",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "kit.ean",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "9" } },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { md: "12" } },
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { md: "6" } },
                                    [
                                      _c("FormulateInput", {
                                        staticClass: "required input-uppercase",
                                        attrs: {
                                          id: "name",
                                          "is-edit": false,
                                          type: "text",
                                          label: _vm.$t("Nome"),
                                          validation: "required",
                                        },
                                        model: {
                                          value: _vm.kit.name,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.kit, "name", $$v)
                                          },
                                          expression: "kit.name",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { md: "2" } },
                                    [
                                      _c("FormulateInput", {
                                        attrs: {
                                          id: "active_online",
                                          type: "switch",
                                          label: _vm.$t("Vender no site"),
                                        },
                                        model: {
                                          value: _vm.kit.activeOnline,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.kit,
                                              "activeOnline",
                                              $$v
                                            )
                                          },
                                          expression: "kit.activeOnline",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { md: "4" } },
                                    [
                                      _c("FormulateInput", {
                                        attrs: {
                                          id: "is_cooler_beer",
                                          type: "switch",
                                          label: _vm.$t("Chopeira?"),
                                        },
                                        model: {
                                          value: _vm.kit.isBeerCoolerKit,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.kit,
                                              "isBeerCoolerKit",
                                              $$v
                                            )
                                          },
                                          expression: "kit.isBeerCoolerKit",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { md: "6" } },
                                    [
                                      _c("FormulateInput", {
                                        attrs: {
                                          id: "description",
                                          type: "textarea",
                                          label: _vm.$t("Descrição"),
                                          rows: "5",
                                        },
                                        model: {
                                          value: _vm.kit.description,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.kit,
                                              "description",
                                              $$v
                                            )
                                          },
                                          expression: "kit.description",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { md: "4" } },
                                    [
                                      _c("FormulateInput", {
                                        ref: "inputPeriod",
                                        attrs: {
                                          id: "kit-period",
                                          type: "date-range-picker",
                                          label: _vm.$t("Período"),
                                          instruction: _vm.$t(
                                            "Período que o kit estará disponivel para venda."
                                          ),
                                        },
                                        model: {
                                          value: _vm.kit.period,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.kit, "period", $$v)
                                          },
                                          expression: "kit.period",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { md: "3" } },
                                    [
                                      _c("FormulateInput", {
                                        attrs: {
                                          id: "kit-allow_all_stores",
                                          type: "switch",
                                          instruction: _vm.$t(
                                            "Quando selecionado, o Kit estará disponível para todas as lojas."
                                          ),
                                          label: _vm.$t("Todas as lojas?"),
                                        },
                                        model: {
                                          value: _vm.kit.allowAllStores,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.kit,
                                              "allowAllStores",
                                              $$v
                                            )
                                          },
                                          expression: "kit.allowAllStores",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  !_vm.kit.allowAllStores
                                    ? _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c("e-store-combo", {
                                            staticClass: "required",
                                            attrs: {
                                              id: "kit-stores_selected",
                                              type: "vue-select",
                                              multiple: true,
                                              label: _vm.$t(
                                                "Lojas participantes"
                                              ),
                                              validation: "required",
                                            },
                                            model: {
                                              value: _vm.kit.storesSelected,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.kit,
                                                  "storesSelected",
                                                  $$v
                                                )
                                              },
                                              expression: "kit.storesSelected",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { md: "3" } },
                                    [
                                      _c("FormulateInput", {
                                        attrs: {
                                          id: "kit-is_credit",
                                          type: "switch",
                                          instruction: _vm.$t(
                                            "Quando selecionado, o Kit estará disponível para venda como crédito"
                                          ),
                                          label: _vm.$t("Produto Crédito?"),
                                        },
                                        model: {
                                          value: _vm.kit.isCredit,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.kit, "isCredit", $$v)
                                          },
                                          expression: "kit.isCredit",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm.kit.isCredit
                                    ? _c(
                                        "b-col",
                                        { attrs: { md: "4" } },
                                        [
                                          _c("FormulateInput", {
                                            staticClass: "required",
                                            attrs: {
                                              id: "kit-credit_type",
                                              type: "vue-select",
                                              validation: "required",
                                              label: _vm.$t("Tipo de Crédito"),
                                              placeholder: _vm.$t("Selecione"),
                                              options: _vm.productCreditTypes(),
                                            },
                                            model: {
                                              value: _vm.kit.creditType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.kit,
                                                  "creditType",
                                                  $$v
                                                )
                                              },
                                              expression: "kit.creditType",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.kit.isCredit
                                    ? _c(
                                        "b-col",
                                        { attrs: { md: "3" } },
                                        [
                                          _c("FormulateInput", {
                                            attrs: {
                                              id: "kit-is_available_for_gift_card",
                                              type: "switch",
                                              instruction: _vm.$t(
                                                "Quando selecionado, o Kit estará disponível para venda utilizando um cartão de presente de chope"
                                              ),
                                              label: _vm.$t(
                                                "Disp. p/ Gift Card?"
                                              ),
                                            },
                                            model: {
                                              value:
                                                _vm.kit
                                                  .isAvailableForChoppGiftCard,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.kit,
                                                  "isAvailableForChoppGiftCard",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "kit.isAvailableForChoppGiftCard",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-card-actions",
            {
              attrs: {
                "action-collapse": "",
                title: _vm.$t("Produtos"),
                "show-loading": _vm.loading,
              },
            },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "d-flex justify-content-end" },
                    [
                      _c("e-button", {
                        attrs: {
                          variant: "primary",
                          text: _vm.$t("Adicionar Produto"),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.onShowSidebar()
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mt-2" },
                [
                  _c(
                    "b-col",
                    [
                      _c("b-table", {
                        ref: "productTable",
                        staticClass: "bordered",
                        attrs: {
                          "show-empty": "",
                          responsive: "",
                          fields: _vm.fields,
                          items: _vm.getKitItems,
                          "empty-text": _vm.$t("Adicione produtos ao seu Kit"),
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "cell(unitDiscount)",
                            fn: function (row) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatDiscount(
                                        row.item.unitDiscount,
                                        row.item.discountType
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                          {
                            key: "cell(skuProduct)",
                            fn: function (row) {
                              return [
                                _c("e-product-router-link", {
                                  attrs: {
                                    "sku-id": row.item.skuProduct.id,
                                    "product-id": row.item.skuProduct.productId,
                                    "product-name": row.item.skuProduct.name,
                                    ean: row.item.skuProduct.ean,
                                  },
                                }),
                              ]
                            },
                          },
                          {
                            key: "cell(action)",
                            fn: function (row) {
                              return [
                                _c("e-grid-actions", {
                                  attrs: {
                                    "show-update": true,
                                    "show-delete": true,
                                  },
                                  on: {
                                    update: function ($event) {
                                      return _vm.onShowSidebar(row)
                                    },
                                    delete: function ($event) {
                                      return _vm.onDeleteKitItem(row)
                                    },
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("e-crud-actions", {
        attrs: { busy: _vm.loading },
        on: { save: _vm.onSaveKit, cancel: _vm.onCancel },
      }),
      _c("product-kit-item-sidebar", {
        ref: "productKitItemSidebar",
        attrs: { "kit-items": _vm.getKitItems },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }