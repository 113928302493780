<template>
  <e-sidebar
    id="sidebar-form-product-kit"
    :title="isKitItemUpdate ? $t('Editar item do kit') : $t('Novo item do kit')"
    :show="showSidebar"
    :fetching="fetching"
    :saving="saving"
    width="550px"
    @save="onAddKitItem"
    @hidden="onHideSidebar"
  >
    <template #content>
      <FormulateForm
        ref="kitSidebarForm"
        name="kitSidebarForm"
      >
        <b-row>
          <b-col>
            <e-search-sku
              id="kit_item_sidebar-sku_id"
              v-model="kitItemForm.skuProduct"
              value-is-object
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col md="4">
            <FormulateInput
              id="kit_item_sidebar-quantity"
              v-model.number="kitItemForm.quantity"
              :is-edit="false"
              type="text-number"
              class="required"
              :label="$t('Quantidade')"
              validation="required|min:0.001"
              :precision="2"
            />
          </b-col>
          <b-col md="6">
            <FormulateInput
              id="kit_item_sidebar-editable_quantity"
              v-model="kitItemForm.quantityIsEditable"
              type="switch"
              :instruction="
                $t('Quando selecionado, a quantidade deste item poderá ser alterada no caixa.')
              "
              :label="$t('Quantidade editável?')"
            />
          </b-col>
          <b-col md="4">
            <FormulateInput
              id="discount-type"
              v-model="kitItemForm.discountType"
              type="radio"
              :options="{
                [discountTypesEnum.VALUE]: 'R$',
                [discountTypesEnum.PERCENTAGE]: '%',
              }"
              :label="$t('Desconto em')"
              :element-class="['d-flex', 'mt-1']"
            />
          </b-col>

          <b-col md="4">
            <FormulateInput
              id="kit_item_sidebar-discount_percentage"
              v-model="kitItemForm.unitDiscount"
              :type="isDiscountPercent ? 'text-percetage' : 'text-number'"
              :label="`${$t('Desconto unit.')} (${getDiscountSymbol})`"
              :currency="getDiscountSymbol"
              :currency-symbol-position="isDiscountPercent ? 'suffix' : 'prefix'"
              :precision="2"
            />
            <!-- <FormulateInput
              v-if="isDiscountPercent"
              id="kit_item_sidebar-discount_percentage"
              v-model="kitItemForm.unitDiscount"
              type="text-percetage"
              :label="`${$t('Desconto')} (${getDiscountSymbol})`"
              :currency="getDiscountSymbol"
              currency-symbol-position="suffix"
              validation="required"
              class="required"
            />
            <FormulateInput
              v-else
              id="kit_item_sidebar-discount_value"
              v-model="kitItemForm.unitDiscount"
              type="text-number"
              :precision="2"
              :label="`${$t('Desconto')} (${getDiscountSymbol})`"
              currency-symbol-position="prefix"
              :currency="getDiscountSymbol"
              validation="required"
              class="required"
            /> -->
          </b-col>
        </b-row>
      </FormulateForm>
    </template>
  </e-sidebar>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import ESidebar from '@/views/components/ESidebar.vue'
import { discountTypes } from '@/mixins'
import { getInitialKitItemForm } from '@/store/pages/catalog/product-kits/product-kit-maintain'
import { mapActions } from 'vuex/'
import ESearchSku from '@/views/components/inputs/ESearchSku.vue'

export default {
  components: { BRow, BCol, ESidebar, ESearchSku },

  mixins: [discountTypes],

  props: {
    kitItems: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      isKitItemUpdate: false,
      kitItemIndex: null,
      showSidebar: false,
      fetching: false,
      edit: false,
      saving: false,
      localProductKitId: null,
      kitItemForm: getInitialKitItemForm(),
    }
  },

  computed: {
    isDiscountPercent() {
      return this.kitItemForm.discountType === this.discountTypesEnum.PERCENTAGE
    },

    getDiscountSymbol() {
      return this.isDiscountPercent ? this.$t('%') : this.$t('R$')
    },
  },

  methods: {
    ...mapActions('pages/catalog/productKits/productKitMaintain', {
      stAddKitItem: 'addKitItem',
      stUpdateKitItem: 'updateKitItem',
    }),

    show(row) {
      this.cleanSidebar()
      this.showSidebar = true

      if (row) {
        this.kitItemForm = { ...row.item }
        this.kitItemIndex = row.index
        this.isKitItemUpdate = true
      }
    },

    onHideSidebar() {
      this.cleanSidebar()
      this.showSidebar = false
      this.$formulate.resetValidation('kitSidebarForm')
    },

    cleanSidebar() {
      this.isKitItemUpdate = false
      this.kitItemIndex = null
      this.kitItemForm = getInitialKitItemForm()
    },

    async onAddKitItem() {
      this.$refs.kitSidebarForm.showErrors()
      if (this.$refs.kitSidebarForm.hasErrors) {
        this.showInvalidDataMessage()
        return
      }

      if (this.isKitItemUpdate) {
        await this.stUpdateKitItem({ kitItem: this.kitItemForm })
      } else {
        await this.stAddKitItem(this.kitItemForm)
      }

      this.onHideSidebar()
    },
  },
}
</script>

<style></style>
